export function SlideGroupIcon(props: { className: string }): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 60 40'
      className={props.className}
    >
      <rect
        x='0.557251'
        y='0.5'
        width='59'
        height='39'
        rx='3.5'
        fill='black'
        stroke='#303436'
      />
      <g filter='url(#filter0_d_65057_7135)'>
        <ellipse cx='30.0573' cy='19.5' rx='17' ry='11.5' fill='#8B9294' />
      </g>
      <path
        d='M29.217 13.5612C29.6109 12.9508 30.5036 12.9508 30.8975 13.5612L32.2977 15.7308C32.4607 15.9834 32.7287 16.1492 33.0275 16.1824L35.6208 16.4706C36.5227 16.5708 36.8356 17.7239 36.108 18.2662L34.6193 19.3759C34.303 19.6117 34.1549 20.0115 34.2412 20.3965L34.6371 22.1616C34.8134 22.9475 34.0356 23.6087 33.2883 23.3083L30.4303 22.1592C30.1909 22.063 29.9236 22.063 29.6842 22.1592L26.8262 23.3083C26.0789 23.6087 25.3011 22.9475 25.4774 22.1616L25.8733 20.3965C25.9597 20.0115 25.8115 19.6117 25.4952 19.3759L24.0065 18.2662C23.2789 17.7239 23.5918 16.5708 24.4937 16.4706L27.087 16.1824C27.3858 16.1492 27.6538 15.9834 27.8168 15.7308L29.217 13.5612Z'
        fill='black'
      />
      <defs>
        <filter
          id='filter0_d_65057_7135'
          x='13.0573'
          y='8'
          width='34'
          height='26'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.360417 0 0 0 0 0.360417 0 0 0 0 0.360417 0 0 0 1 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_65057_7135'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_65057_7135'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
