export function MobileIcon(props: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      className={props.className || 'w-6 h-6 fill-current'}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9 1.8H15C16.4912 1.8 17.7 3.00883 17.7 4.5V19.5C17.7 20.9912 16.4912 22.2 15 22.2H9C7.50883 22.2 6.3 20.9912 6.3 19.5V4.5C6.3 3.00883 7.50883 1.8 9 1.8ZM4.5 4.5C4.5 2.01472 6.51472 0 9 0H15C17.4853 0 19.5 2.01472 19.5 4.5V19.5C19.5 21.9853 17.4853 24 15 24H9C6.51472 24 4.5 21.9853 4.5 19.5V4.5ZM10.3125 19.5C10.0018 19.5 9.75 19.8358 9.75 20.25C9.75 20.6642 10.0018 21 10.3125 21H13.6875C13.9982 21 14.25 20.6642 14.25 20.25C14.25 19.8358 13.9982 19.5 13.6875 19.5H10.3125Z'
      />
    </svg>
  );
}
