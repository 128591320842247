import { useRef, useState } from 'react';

import {
  type DtoGamePack,
  type DtoUpdateGamePackRequest,
  EnumsGamePackChangeLevel,
  EnumsMediaScene,
} from '@lp-lib/api-service-client/public';
import { type Media } from '@lp-lib/media';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { fromMediaDTO } from '../../../utils/api-dto';
import { MediaUploader } from '../../MediaUploader/MediaUploader';

export function TrainingEditorSettings(props: {
  pack: DtoGamePack;
  onCancel: () => void;
  onSave: (pack: DtoUpdateGamePackRequest) => void;
}) {
  const { onCancel, onSave } = props;
  const courseNameRef = useRef<HTMLInputElement | null>(null);
  const handleSave = useLiveCallback(() => {
    const pack: DtoUpdateGamePackRequest = {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,

      name: courseNameRef.current?.value ?? props.pack.name,
      coverMediaId: cover?.id ?? '',
      marketingSettings: {
        lobbyBackground: {
          data: {
            id: background?.id ?? '',
          },
        },
      },
    };

    onSave(pack);
  });

  const [isUploading, setIsUploading] = useState(false);

  const [cover, setCover] = useState<Media | null>(
    fromMediaDTO(props.pack.cover)
  );
  const [background, setBackground] = useState<Media | null>(
    fromMediaDTO(props.pack.marketingSettings?.lobbyBackground?.media ?? null)
  );

  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-165 min-h-45'>
      <div className='w-full h-full flex flex-col gap-5 text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-base'>Course Settings</div>
        </div>

        <label className='w-full flex flex-col gap-1.5 flex-1 min-h-0 pt-2 pb-4'>
          <span className='text-sm font-bold'>Course Name</span>
          <input
            ref={courseNameRef}
            className={`w-full h-12.5 field m-0`}
            placeholder='Course Name'
            defaultValue={props.pack.name}
          />
        </label>

        <div className='flex gap-7.5'>
          <label className='flex-1 flex flex-col gap-1.5'>
            <span className='text-sm font-bold'>Course Cover</span>
            <MediaUploader
              video={false}
              scene={EnumsMediaScene.MediaSceneGamePackCover}
              media={cover}
              onUploadStart={() => setIsUploading(true)}
              onUploadSuccess={(media) => {
                setCover(media);
                setIsUploading(false);
              }}
              onUploadFailed={() => setIsUploading(false)}
              onMediaDelete={() => {
                setCover(null);
              }}
              width='w-full'
            />
          </label>

          <label className='flex-1 flex flex-col gap-1.5'>
            <span className='text-sm font-bold'>Course Background</span>
            <MediaUploader
              video={false}
              scene={EnumsMediaScene.MediaSceneGamePackMarketingMaterials}
              media={background}
              onUploadStart={() => setIsUploading(true)}
              onUploadSuccess={(media) => {
                setBackground(media);
                setIsUploading(false);
              }}
              onUploadFailed={() => setIsUploading(false)}
              onMediaDelete={() => {
                setBackground(null);
              }}
              width='w-full'
            />
          </label>
        </div>

        <div className='mt-auto w-full flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={onCancel}
            disabled={isUploading}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
            disabled={isUploading}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
