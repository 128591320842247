import './dialogueEditor.css';

import { nanoid } from '@reduxjs/toolkit';
import {
  type Content,
  type Editor,
  type EditorEvents,
  mergeAttributes,
  Node,
} from '@tiptap/core';
import { Dropcursor } from '@tiptap/extension-dropcursor';
import { History } from '@tiptap/extension-history';
import { Placeholder } from '@tiptap/extension-placeholder';
import {
  EditorProvider,
  NodeViewContent,
  type NodeViewProps,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useCurrentEditor,
} from '@tiptap/react';
import { type Node as ProsemirrorNode } from 'prosemirror-model';
import { Plugin, type Transaction } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { useEffect, useMemo, useRef, useState } from 'react';
import Select, { type SingleValue } from 'react-select';

import {
  type DtoPersonality,
  EnumsDialogueGenerationType,
  EnumsDialogueOfflineRenderStatus,
  type ModelsDialogue,
  type ModelsDialogueEntry,
} from '@lp-lib/api-service-client/public';
import { MediaFormatVersion } from '@lp-lib/media';

import { useLiveCallback } from '../../hooks/useLiveCallback';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { fromMediaDTO } from '../../utils/api-dto';
import { uuidv4 } from '../../utils/common';
import { MediaUtils } from '../../utils/media';
import { buildReactSelectStyles } from '../../utils/react-select';
import { LockIcon } from '../icons/LockIcon';
import { NoRenderIcon, RenderIcon } from '../icons/RenderIcon';
import { UnlockIcon } from '../icons/UnlockIcon';
import { Loading } from '../Loading';
import { sharedPersonalitySelectComponents } from './PersonalitySelect';
import { usePersonalities } from './usePersonalities';
import { VoiceOverUtils } from './utils';

export function DialogueEditor(props: {
  value: ModelsDialogue | null | undefined;
  onChange: (val: ModelsDialogue) => void;
  offlineRendering: boolean;
}) {
  const { value, onChange, offlineRendering } = props;
  const { data: personalities, isLoading: loadingPersonalities } =
    usePersonalities();
  const defaultPersonality = useMemo(() => {
    return personalities?.at(0);
  }, [personalities]);

  const personalitiesMap = useMemo(() => {
    return new Map(personalities?.map((p) => [p.id, p]));
  }, [personalities]);

  const handleChange = useLiveCallback((val: ModelsDialogue) => {
    if (offlineRendering) {
      val.entries = val.entries.map((entry) => {
        const personality = personalitiesMap.get(entry.personalityId);
        if (!personality?.avatarId) return entry;
        return {
          ...entry,
          generationType:
            EnumsDialogueGenerationType.DialogueGenerationTypeOffline,
          offlineRender: {
            status:
              EnumsDialogueOfflineRenderStatus.DialogueOfflineRenderStatusNotStarted,
          },
        };
      });
    }
    onChange(val);
  });

  const containsRenderedAvatars = VoiceOverUtils.ContainsRenderedAvatars(value);
  const requiresRender = VoiceOverUtils.IsDialogueRequiresRender(value);
  const isRendering = VoiceOverUtils.IsDialogueRendering(value);

  const lockable = containsRenderedAvatars || isRendering;
  const [locked, setLocked] = useState(
    () => containsRenderedAvatars || isRendering
  );

  useEffect(() => {
    if (!isRendering) return;
    setLocked(true);
  }, [isRendering]);

  return (
    <div className='w-full h-full flex flex-col gap-1'>
      <div className='flex-none flex items-center justify-between'>
        <p className='text-base text-white font-bold'>Voice Over Script</p>

        {lockable && (
          <div className='relative mr-1 group'>
            <button
              type='button'
              className='w-5 h-5 text-white disabled:text-icon-gray disabled:cursor-not-allowed flex items-center justify-center'
              onClick={() => setLocked(!locked)}
              disabled={isRendering}
            >
              {locked ? (
                <LockIcon className='w-3.5 h-3.5 fill-current' />
              ) : (
                <UnlockIcon className='w-3.5 h-3.5 fill-current' />
              )}
            </button>

            <div className='absolute top-0 right-full z-5 hidden group-hover:block'>
              <div className='w-48 bg-black border border-secondary text-white rounded-lg px-4 py-2 text-3xs'>
                {isRendering
                  ? 'This script is locked while the script avatars are rendering'
                  : 'Editing a rendered avatar script will require another render'}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='min-h-0 flex-1 overflow-hidden w-full border border-secondary rounded-xl bg-black flex flex-col'>
        <div
          className={`w-full flex-1 min-h-0 ${
            (lockable && locked) || loadingPersonalities
              ? 'opacity-40 pointer-events-none'
              : ''
          }`}
        >
          {loadingPersonalities ? (
            <Loading
              text=''
              imgClassName='w-5 h-5'
              containerClassName='w-full h-full flex items-center justify-center'
            />
          ) : !defaultPersonality?.id ? (
            <div className='w-full h-full flex items-center justify-center px-2 text-sms text-red-002'>
              No personalities found
            </div>
          ) : (
            <RichDialogueEditor
              value={value}
              onChange={handleChange}
              defaultPersonalityId={defaultPersonality.id}
            />
          )}
        </div>
        <div className='flex-none w-full bg-layer-001 p-2 text-sms'>
          {isRendering ? (
            <div className='text-icon-gray flex items-center gap-1'>
              <RenderIcon className='w-4 h-4 fill-current' />
              Rendering Avatar ...
            </div>
          ) : requiresRender ? (
            <div className='text-tertiary flex items-center gap-1'>
              <RenderIcon className='w-4 h-4 fill-current' />
              Avatar Render Required
            </div>
          ) : (
            <div className='text-icon-gray flex items-center gap-1'>
              <NoRenderIcon className='w-4 h-4 fill-current' />
              No Avatar Render Required
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

// This is the react view for the dialogue entry in the RTE, which is needed to pull in the PersonalitySelector. It's
// quite simple except that once react-select pops up, and the user interacts with it, the editor will fire a blur
// event. Changing the personality on an entry needs to trigger a change to the caller, so we manually trigger it here.
// It's a pain to get the callback passed in here, but it's accomplished by way of the extension options.
function EntryComponent(props: NodeViewProps) {
  const tt = useCurrentEditor();
  const handleChange = useLiveCallback((p: DtoPersonality) => {
    props.updateAttributes({
      personalityId: p.id,
    });
    const ext = props.extension as Node<EntryOptions>;
    ext.options.onChange(tt?.editor);
  });

  return (
    <NodeViewWrapper className='relative w-full min-h-7 flex items-center group'>
      <div className='absolute top-0 left-0' contentEditable={false}>
        <PersonalitySelector
          onChange={handleChange}
          value={props.node.attrs.personalityId}
          forceShow={props.decorations.some((d) => d.spec.forceShow)}
        />
      </div>
      <NodeViewContent className='entry-placeholder text-sms flex-1 pl-9' />
    </NodeViewWrapper>
  );
}

function PersonalitySelector(props: {
  value?: string | null;
  onChange: (value: DtoPersonality) => void;
  forceShow?: boolean;
}): JSX.Element {
  const { data, isLoading } = usePersonalities();

  const styles = useMemo(
    () =>
      buildReactSelectStyles<DtoPersonality>({
        override: {
          control: {
            background: '#232325',
            borderRadius: '4px',
            cursor: 'pointer',
            width: '100%',
            minHeight: 'auto',
            padding: '3px 1px',
          },
          menu: {
            width: '100%',
          },
          valueContainer: {
            width: '100%',
            padding: '0',
          },
          dropdownIndicator: {
            padding: '0',
          },
          input: {
            padding: '0 2px',
            margin: '0',
          },
        },
      }),
    []
  );

  const selectedValue = useMemo(() => {
    if (!props.value) return null;
    return data?.find((item) => item.id === props.value) ?? null;
  }, [data, props.value]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = useLiveCallback(() => {
    setHovered(true);
  });
  const handleMouseLeave = useLiveCallback(() => {
    if (menuOpen) return;
    setHovered(false);
  });
  const ref = useRef<HTMLDivElement | null>(null);
  useOutsideClick(ref, () => {
    setMenuOpen(false);
    setHovered(false);
  });
  const handleSingleChange = (option: SingleValue<DtoPersonality>) => {
    if (!option) return;
    props.onChange(option);
    setMenuOpen(false);
    setHovered(false);
  };
  const opacity =
    hovered || menuOpen || props.forceShow
      ? 'opacity-100'
      : 'group-hover:opacity-60 opacity-0';

  return (
    <div
      ref={ref}
      className={`relative transition-opacity select-none ${opacity}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`p-[3px] ${
          hovered ? 'opacity-100' : 'opacity-100'
        } border border-transparent`}
      >
        <ProfileImage personality={selectedValue} />
      </div>
      <div
        className={`
          absolute top-0 left-0
           ${hovered ? 'opacity-100 w-42' : 'opacity-0 w-14'}
           transition-size
        `}
      >
        {hovered && (
          <Select<DtoPersonality, false>
            styles={styles}
            options={data}
            value={selectedValue}
            getOptionLabel={(option) => option.displayLabel}
            getOptionValue={(option) => option.id}
            onChange={handleSingleChange}
            isLoading={isLoading}
            isSearchable
            components={{
              IndicatorSeparator: () => null,
              ...sharedPersonalitySelectComponents,
            }}
            menuPosition='fixed'
            onMenuOpen={() => setMenuOpen(true)}
            onMenuClose={() => setMenuOpen(false)}
          />
        )}
      </div>
    </div>
  );
}

function ProfileImage(props: { personality: Nullable<DtoPersonality> }) {
  const profileImageUrl = useMemo(() => {
    return MediaUtils.PickMediaUrl(
      fromMediaDTO(props.personality?.profileImage?.media),
      {
        priority: [MediaFormatVersion.SM],
      }
    );
  }, [props.personality?.profileImage?.media]);

  return (
    <div className='flex-none w-5 h-5 rounded-full bg-gray-500 overflow-hidden'>
      {profileImageUrl && (
        <img
          src={profileImageUrl}
          alt={props.personality?.displayLabel}
          className='w-full h-full object-cover'
        />
      )}
    </div>
  );
}

// this is the core editor schema for ModelsDialogue.
const Dialogue = Node.create({
  name: 'dialogue',
  topNode: true,
  content: 'entry+',
  parseHTML() {
    return [{ tag: 'dialogue' }];
  },
});

type EntryOptions = {
  defaultPersonalityId: string;
  onChange: (editor: Nullable<Editor>) => void;
};

const Entry = Node.create<EntryOptions>({
  name: 'entry',
  content: 'inline*',
  addAttributes() {
    return {
      id: uuidv4(),
      personalityId: this.options.defaultPersonalityId,
    };
  },
  parseHTML() {
    return [
      {
        tag: 'entry',
        getAttrs(node) {
          return {
            id: node.dataset.id,
            personalityId: node.dataset.personalityId,
          };
        },
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    return [
      'entry',
      mergeAttributes(
        {
          'data-id': node.attrs.id,
          'data-personality-id': node.attrs.personalityId,
        },
        HTMLAttributes
      ),
      0,
    ];
  },
  addNodeView() {
    return ReactNodeViewRenderer(EntryComponent);
  },
  addProseMirrorPlugins() {
    const defaultPersonalityId = this.options.defaultPersonalityId;
    return [
      // the purpose of this plugin is to decorate the first entry of a personality with `forceShow`.
      // we `forceShow` the first entry, and subsequent entries with the same personality will be hidden.
      new Plugin({
        props: {
          decorations(state) {
            const decorations: Decoration[] = [];
            let currentPersonalityId: Nullable<string> = null;
            state.doc.descendants((node, pos) => {
              if (node.type.name === 'entry') {
                if (node.attrs.personalityId !== currentPersonalityId) {
                  decorations.push(
                    Decoration.node(
                      pos,
                      pos + node.nodeSize,
                      {},
                      { forceShow: true }
                    )
                  );
                  currentPersonalityId = node.attrs.personalityId;
                }
                // don't descend into entries.
                return false;
              }
            });

            return DecorationSet.create(state.doc, decorations);
          },
        },
        // the purpose of this transaction is to ensure that every entry has a personalityId.
        // if a transaction on the editor, for instance, select all and delete, removes all entries,
        // we want to be sure the resulting entry has a personalityId set.
        appendTransaction(_, __, state) {
          let tx: Transaction | null = null;
          state.doc.descendants((node, pos) => {
            if (node.type.name === 'entry') {
              if (!node.attrs.personalityId) {
                if (!tx) {
                  tx = state.tr;
                }
                tx.setNodeAttribute(pos, 'personalityId', defaultPersonalityId);
              }
              return false;
            }
          });
          return tx;
        },
      }),
    ];
  },
});

const TriggerPoint = Node.create({
  name: 'triggerPoint',
  inline: true,
  group: 'inline',
  draggable: true,
  selectable: false,
  atom: true,
  addAttributes() {
    return { name: '' };
  },
  parseHTML() {
    return [
      {
        tag: 'mark',
      },
    ];
  },
  renderHTML({ node, HTMLAttributes }) {
    const displayName = getTriggerDisplayName(String(node.attrs.name)) ?? '🚩';
    return [
      'mark',
      mergeAttributes(
        {
          title: node.attrs.name,
        },
        HTMLAttributes
      ),
      displayName,
    ];
  },
  addKeyboardShortcuts() {
    return {
      // this is a keyboard shortcut to simplify adding a trigger point.
      'Ctrl-Space': ({ editor }) => {
        return editor
          .chain()
          .command(({ state, tr }) => {
            const type = editor.schema.nodes.triggerPoint;
            const { $from } = tr.selection;
            if (
              !$from.parent.canReplaceWith($from.index(), $from.index(), type)
            )
              return false;

            const preferredTriggers = [...Array(10).keys()].map(
              (i) => `trigger-${i + 1}`
            );

            state.doc.descendants((node) => {
              if (node.type.name === 'triggerPoint') {
                const index = preferredTriggers.indexOf(node.attrs.name);
                if (index !== -1) {
                  preferredTriggers.splice(index, 1);
                }
              }
            });

            const name = preferredTriggers.shift() ?? `trigger-${nanoid(4)}`;

            tr.replaceSelectionWith(type.create({ name }));
            return true;
          })
          .run();
      },
    };
  },
});

const Text = Node.create({
  name: 'text',
  group: 'inline',
  inline: true,
});

function RichDialogueEditor(props: {
  value: Nullable<ModelsDialogue>;
  onChange: (value: ModelsDialogue) => void;
  defaultPersonalityId: string;
}) {
  const content = useMemo(
    () => fromDialogueToEditor(props.value, props.defaultPersonalityId),
    [props.value, props.defaultPersonalityId]
  );

  const handleBlur = useLiveCallback(({ editor }: EditorEvents['blur']) => {
    props.onChange(fromEditorToDialogue(editor.state.doc));
  });

  const handleChange = useLiveCallback((editor: Nullable<Editor>) => {
    if (!editor) return;
    props.onChange(fromEditorToDialogue(editor.state.doc));
  });

  const extensions = useMemo(() => {
    return [
      // our editor schema.
      Dialogue,
      Entry.configure({
        defaultPersonalityId: props.defaultPersonalityId,
        onChange: handleChange,
      }),
      TriggerPoint,
      Text,
      // an extension that will show a blinking cursor when dragging a triggerPoint.
      Dropcursor,
      // an extension to add basic edit history, undo/redo.
      History,
      // this extension is needed to show placeholder text, but it does not work well with react node views.
      // the extension is needed to decorate the editor/nodes with relevant `is-empty` classes, but we use
      // custom css to show the placeholder text.
      Placeholder.configure({
        placeholder: '', // this is handled by custom css.
        showOnlyWhenEditable: false,
        showOnlyCurrent: false,
      }),
    ];
  }, [handleChange, props.defaultPersonalityId]);

  return (
    <div className='relative w-full h-full overflow-y-auto scrollbar bg-black text-white px-2 py-3'>
      <EditorProvider
        extensions={extensions}
        content={content}
        editorProps={{
          attributes: {
            class: 'w-full h-full appearance-none outline-none space-y-2',
          },
        }}
        immediatelyRender={true}
        shouldRerenderOnTransaction={false}
        onBlur={handleBlur}
      />
    </div>
  );
}

// the purpose of this function is to convert the editor schema to our ModelsDialogue.
// this function assumes everything will be client side generated, which is not the case.
// a higher level component can remap the generationType to the appropriate value.
function fromEditorToDialogue(doc: ProsemirrorNode): ModelsDialogue {
  const entries: ModelsDialogueEntry[] = [];

  let currentPersonalityId: Nullable<string> = null;
  let scriptLines: string[] = [];
  for (let i = 0; i < doc.childCount; i++) {
    const node = doc.child(i);
    if (node.type.name === 'entry') {
      if (node.attrs.personalityId !== currentPersonalityId) {
        if (currentPersonalityId) {
          entries.push({
            id: uuidv4(),
            generationType:
              EnumsDialogueGenerationType.DialogueGenerationTypeClient,
            personalityId: currentPersonalityId,
            script: scriptLines.join('\n'),
          });
        }
        currentPersonalityId = node.attrs.personalityId;
        scriptLines = [];
      }
      const textContent: string[] = [];
      node.descendants((child) => {
        if (child.isText) {
          textContent.push(child.textContent);
        } else if (child.type.name === 'triggerPoint') {
          textContent.push(`<mark name="${child.attrs.name}" />`);
        }
      });
      scriptLines.push(textContent.join(''));
    }
  }
  if (currentPersonalityId) {
    entries.push({
      id: uuidv4(),
      generationType: EnumsDialogueGenerationType.DialogueGenerationTypeClient,
      personalityId: currentPersonalityId,
      script: scriptLines.join('\n'),
    });
  }
  return { entries };
}

// the purpose of this function is to convert our ModelsDialogue to the editor schema.
// it maps our datamodel to an html representation that the editor can understand.
function fromDialogueToEditor(
  dialogue: Nullable<ModelsDialogue>,
  defaultPersonalityId: string
): Content {
  const doc = document.createElement('dialogue');

  for (const entry of dialogue?.entries ?? []) {
    entry.script.split('\n').forEach((line) => {
      const e = document.createElement('entry');
      e.dataset.id = entry.id;
      e.dataset.personalityId = entry.personalityId;
      // innerHTML doesn't like self closing tags...
      e.innerHTML = line.replaceAll(
        /<mark name="([^"]+)"\s*\/>/g,
        '<mark name="$1"></mark>'
      );
      doc.appendChild(e);
    });
  }

  // add a blank entry.
  if (doc.childElementCount === 0) {
    const e = document.createElement('entry');
    e.dataset.id = uuidv4();
    e.dataset.personalityId = defaultPersonalityId;
    doc.appendChild(e);
  }

  return doc.outerHTML;
}

const emojiMap: string[] = [
  '',
  '1️⃣',
  '2️⃣',
  '3️⃣',
  '4️⃣',
  '5️⃣',
  '6️⃣',
  '7️⃣',
  '8️⃣',
  '9️⃣',
  '🔟',
];

export function getTriggerDisplayName(trigger: string): Nullable<string> {
  const match = trigger.match(/^trigger-(\d+)$/);
  if (match) {
    const number = parseInt(match[1], 10);
    if (number >= 1 && number <= 10) {
      return emojiMap[number];
    }
  }
  return null;
}
