export function DesktopIcon(props: { className?: string }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      className={props.className || 'w-6 h-6 fill-current'}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.5 3.3H19.5C20.9912 3.3 22.2 4.50883 22.2 6V15C22.2 16.4912 20.9912 17.7 19.5 17.7H4.5C3.00883 17.7 1.8 16.4912 1.8 15V6C1.8 4.50883 3.00883 3.3 4.5 3.3ZM0 6C0 3.51472 2.01472 1.5 4.5 1.5H19.5C21.9853 1.5 24 3.51472 24 6V15C24 17.4853 21.9853 19.5 19.5 19.5H15.3V21H17.1C17.5971 21 18 21.4029 18 21.9C18 22.3971 17.5971 22.8 17.1 22.8H6.9C6.40294 22.8 6 22.3971 6 21.9C6 21.4029 6.40294 21 6.9 21H9V19.5H4.5C2.01472 19.5 0 17.4853 0 15V6ZM13.5 21V19.5H10.8V21H13.5Z'
      />
    </svg>
  );
}
