import {
  createContext,
  forwardRef,
  type ReactEventHandler,
  useContext,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';

import { useLiveCallback } from '../../../../hooks/useLiveCallback';

const context = createContext<HTMLIFrameElement | null>(null);

/**
 * This hook provides the iframe element of the device preview for components rendered _within_ the preview.
 * This is used where access to the iframe's window or document are needed, for example, in the editors that use
 * react-dnd. The window must be passed to the dnd provider to work correctly.
 */
export function useDevicePreviewFrame() {
  return useContext(context);
}

export const DevicePreview = forwardRef<
  HTMLIFrameElement,
  { children: React.ReactNode }
>((props, forwardedRef) => {
  const localRef = useRef<HTMLIFrameElement | null>(null);
  const body = useRef<HTMLElement | null>(null);
  const [ready, setReady] = useState(false);

  const setRefs = (element: HTMLIFrameElement | null) => {
    localRef.current = element;
    if (typeof forwardedRef === 'function') {
      forwardedRef(element);
    } else if (forwardedRef) {
      forwardedRef.current = element;
    }
  };

  const handleLoad: ReactEventHandler<HTMLIFrameElement> = useLiveCallback(
    (e) => {
      if (body.current) return;
      const container = e.currentTarget.contentDocument?.body;
      if (!container) return;

      container.className = 'w-screen h-screen overflow-hidden';
      body.current = container;
      setReady(true);
    }
  );

  return (
    <iframe
      ref={setRefs}
      title='Preview'
      className='w-full h-full'
      src='/device-preview-container'
      onLoad={handleLoad}
    >
      <context.Provider value={localRef.current}>
        {ready && body.current && createPortal(props.children, body.current)}
      </context.Provider>
    </iframe>
  );
});
