import { useDebugValue } from 'react';

import { type Block, type BlockFields } from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { useBlockEditorStore } from '../../RoutedBlock';
import { type TrainingSlideEditorProps } from './types';

type Result<T extends Block> = {
  onChange: <F extends keyof BlockFields<T>>(
    field: F,
    value: BlockFields<T>[F]
  ) => void;
  onBlur: <F extends keyof BlockFields<T>>(
    field: F,
    value: BlockFields<T>[F]
  ) => void;
};

export function useTrainingSlideEditor<T extends Block>(
  props: TrainingSlideEditorProps<T>
): Result<T> {
  const store = useBlockEditorStore();
  const { block } = props;
  const blockId = block.id;

  const onChange = useLiveCallback(
    <F extends keyof BlockFields<T>>(field: F, value: BlockFields<T>[F]) => {
      if (!blockId || !field) return;

      store.setBlockField({
        blockId,
        blockField: { [field]: value },
      });
    }
  );

  const onBlur = useLiveCallback(
    async <F extends keyof BlockFields<T>>(
      field: F,
      value: BlockFields<T>[F]
    ) => {
      if (!blockId || !field) return;

      await apiService.block.updateField<T, F>(blockId, {
        field,
        value,
      });
    }
  );

  return {
    onChange,
    onBlur,
  };
}

export function useBlockFieldController<
  B extends Block,
  F extends keyof B['fields']
>(block: B, fieldName: F) {
  const store = useBlockEditorStore();

  const fieldValue = (block.fields as B['fields'])[fieldName];

  const updateLocal = useLiveCallback((value: B['fields'][F]) => {
    store.setBlockField({
      blockId: block.id,
      blockField: { [fieldName]: value },
    });
  });

  const updateRemote = useLiveCallback(async (value: B['fields'][F]) => {
    await apiService.block.updateField<B, F>(block.id, {
      field: fieldName,
      value,
    });
  });

  useDebugValue(fieldValue);

  return {
    value: fieldValue,
    updateLocal,
    updateRemote,
  };
}
