import { useState } from 'react';
import ContentEditable from 'react-contenteditable';

export function EditableText(props: {
  value: string;
  onBlur: (value: string) => void;
}) {
  const { value, onBlur } = props;

  const [html, setHtml] = useState(() => {
    return value || '';
  });

  return (
    <ContentEditable
      tagName='p'
      html={html}
      onChange={(e) => {
        setHtml(e.currentTarget.innerHTML);
      }}
      onBlur={(e) => {
        onBlur(e.currentTarget.innerText);
      }}
      className='w-full outline-none cursor-text whitespace-pre-wrap'
    />
  );
}
