import '../../../components/GameV2/design/styles.css';

import { useNavigate } from '@remix-run/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLatest } from 'react-use';
import { useSnapshot } from 'valtio';

import {
  type DtoBlock,
  type DtoGame,
  type DtoGamePack,
  EnumsGamePackChangeLevel,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { useLiveAsyncCall } from '../../../hooks/useAsyncCall';
import { useInstance } from '../../../hooks/useInstance';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { fromDTOBlocks, fromDTOGame } from '../../../utils/api-dto';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { GameEditorStore } from '../../Game/GameEditorStore';
import { BlockAnimator } from '../../GameV2/apis/BlockAnimationControl';
import { ArrowLeftIcon } from '../../icons/Arrows';
import { DesktopIcon } from '../../icons/DesktopIcon';
import { MobileIcon } from '../../icons/MobileIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { RenderIcon } from '../../icons/RenderIcon';
import { SettingIcon } from '../../icons/SettingIcon';
import { DevicePreview } from './Shared/DevicePreview';
import {
  TrainingEditorDetail,
  TrainingEditorDetailSidebar,
} from './TrainingEditorDetail';
import { TrainingEditorSettings } from './TrainingEditorSettings';
import { TrainingEditorSidebar } from './TrainingEditorSidebar';
import { TrainingPreview } from './TrainingPreview';
import { type TrainingDevice } from './types';
import { TrainingEditorUtils } from './utils';

function BetaBadge(props: { className?: string }) {
  return (
    <div
      className={`w-12.5 h-3.5 uppercase bg-lp-red-002 text-white text-sms 
      font-bold flex items-center justify-center rounded-sm ${
        props.className ?? ''
      }`}
    >
      beta
    </div>
  );
}

function NameField(props: {
  value: string;
  onChange: (value: string) => void;
}) {
  const { value, onChange } = props;

  const [editing, setEditing] = useState(false);

  if (!editing)
    return (
      <button
        type='button'
        className='hover:text-white'
        onClick={() => setEditing(true)}
      >
        {value}
      </button>
    );

  return (
    <input
      className='field mb-0 w-50 h-8'
      defaultValue={value}
      onBlur={(e) => {
        onChange(e.target.value);
        setEditing(false);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.currentTarget.blur();
        }
      }}
      autoFocus
    />
  );
}

function BlockWatcher(props: { store: GameEditorStore; block: Block }) {
  const { store, block } = props;

  const isRendering = TrainingEditorUtils.IsBlockRendering(block);
  const refreshDialogue = useLiveCallback(async () => {
    const resp = await apiService.block.getBlock(block.id);
    const updatedBlock = resp.data.block;
    store.blockEditorStore.setBlockField({
      blockId: updatedBlock.id,
      blockField: {
        dialogue: updatedBlock.fields.dialogue,
      },
    });
  });

  useEffect(() => {
    if (!isRendering) return;

    let intervalId: ReturnType<typeof setInterval> | null = null;
    const timer = setTimeout(() => {
      intervalId = setInterval(refreshDialogue, 30000);
    }, 60000);

    return () => {
      clearTimeout(timer);
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isRendering, refreshDialogue]);

  return null;
}

function SlideGroupWatcher(props: {
  store: GameEditorStore;
  onRequiresRenderChange: (value: boolean) => void;
  onRenderingChange: (value: boolean) => void;
}) {
  const { store, onRequiresRenderChange, onRenderingChange } = props;
  const blocks = useSnapshot(store.blockEditorStore.state).blocks;

  const requiresRender = useMemo(
    () =>
      blocks.some((b) => TrainingEditorUtils.IsBlockRenderRequired(b as Block)),
    [blocks]
  );
  const isRendering = useMemo(
    () => blocks.some((b) => TrainingEditorUtils.IsBlockRendering(b as Block)),
    [blocks]
  );

  const onRequiresRenderChangeRef = useLatest(onRequiresRenderChange);
  useEffect(() => {
    onRequiresRenderChangeRef.current(requiresRender);
  }, [requiresRender, onRequiresRenderChangeRef]);
  const onRenderingChangeRef = useLatest(onRenderingChange);
  useEffect(() => {
    onRenderingChangeRef.current(isRendering);
  }, [isRendering, onRenderingChangeRef]);

  return (
    <>
      {blocks.map((block) => (
        <BlockWatcher key={block.id} store={store} block={block as Block} />
      ))}
    </>
  );
}

function Watcher(props: {
  stores: GameEditorStore[];
  onRequiresRenderChange: (value: boolean) => void;
  onRenderingChange: (value: boolean) => void;
}) {
  const { stores, onRequiresRenderChange, onRenderingChange } = props;
  const requiresRenderSet = useInstance(() => new Set<string>());
  const isRenderingSet = useInstance(() => new Set<string>());

  const handleRenderAvatars = (id: string, value: boolean) => {
    if (value) {
      requiresRenderSet.add(id);
    } else {
      requiresRenderSet.delete(id);
    }

    onRequiresRenderChange(requiresRenderSet.size > 0);
  };
  const handleRendering = (id: string, value: boolean) => {
    if (value) {
      isRenderingSet.add(id);
    } else {
      isRenderingSet.delete(id);
    }
    onRenderingChange(isRenderingSet.size > 0);
  };

  return (
    <>
      {stores.map((store) => (
        <SlideGroupWatcher
          key={store.state.game?.id}
          store={store}
          onRequiresRenderChange={(value) =>
            handleRenderAvatars(store.state.game?.id ?? '', value)
          }
          onRenderingChange={(value) =>
            handleRendering(store.state.game?.id ?? '', value)
          }
        />
      ))}
    </>
  );
}

export function TrainingEditor(props: {
  pack: DtoGamePack;
  games: DtoGame[];
  blocks: DtoBlock[];
}) {
  const { games, blocks } = props;

  const navigate = useNavigate();
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const [pack, setPack] = useState(props.pack);

  const [stores, setStores] = useState<GameEditorStore[]>(() => {
    return games.map((game) => {
      const store = new GameEditorStore();
      store.setEditingGame(fromDTOGame(game), {
        blocks: fromDTOBlocks(blocks.filter((b) => b.gameId === game.id)) || [],
      });
      store.setSelectedBlockId(null);
      return store;
    });
  });
  const [selectedGameId, setSelectedGameId] = useState<string | null>(() =>
    games.length > 0 ? games[0].id : null
  );
  const selectedStore = stores.find((s) => s.state.game?.id === selectedGameId);
  const [device, setDevice] = useState<TrainingDevice>('mobile');
  const [requiresRender, setRequiresRender] = useState(false);
  const [isRendering, setIsRendering] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const devicePreview = useRef<HTMLIFrameElement | null>(null);
  const [animator] = useState(() => new BlockAnimator({}, () => null));

  const handleChangeName = async (value: string) => {
    const resp = await apiService.gamePack.update(pack.id, {
      name: value,
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
    });
    setPack(resp.data.gamePack);
  };

  const {
    call: handleRenderAvatars,
    state: {
      state: { isRunning: isRenderRequesting },
    },
  } = useLiveAsyncCall(async () => {
    const blockIds = stores.flatMap((s) =>
      s.blockEditorStore.state.blocks
        .filter((block) => TrainingEditorUtils.IsBlockRenderRequired(block))
        .map((block) => block.id)
    );

    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='p-5 text-white'>
          <div className='text-2xl font-medium text-center'>
            Render Avatar Videos?
          </div>
          <div className='my-4 text-sms'>
            Rendering avatars will take a few minutes. You can continue to edit
            the other parts of your game while the avatars render.
            <br />
            <br />
            Slides with Avatars: {blockIds.length}
          </div>
        </div>
      ),
      confirmBtnLabel: 'Continue',
      confirmBtnVariant: 'primary',
      cancelBtnLabel: 'Cancel',
    });
    if (result === 'canceled') return;

    const resp = await apiService.block.renderBlocks({ blockIds });
    for (const block of resp.data.blocks) {
      const store = stores.find((s) => s.state.game?.id === block.gameId);
      if (!store) continue;
      store.blockEditorStore.setBlockField({
        blockId: block.id,
        blockField: {
          dialogue: block.fields.dialogue,
        },
      });
    }
  });

  const handleCourseSettingsEdit = useLiveCallback(async () => {
    await triggerModal({
      kind: 'custom',
      element: (p) => {
        return (
          <TrainingEditorSettings
            pack={pack}
            onCancel={p.internalOnCancel}
            onSave={async (change) => {
              p.internalOnConfirm();
              const resp = await apiService.gamePack.update(pack.id, change);
              setPack(resp.data.gamePack);
            }}
          />
        );
      },
    });
  });

  if (isPreviewing) {
    return (
      <TrainingPreview
        pack={pack}
        stores={stores}
        selectedStore={selectedStore}
        defaultDevice={device}
        onClose={() => setIsPreviewing(false)}
      />
    );
  }

  return (
    <div className='w-full h-full bg-layer-001 px-2.5 py-1.5 flex flex-col gap-2'>
      <Watcher
        stores={stores}
        onRequiresRenderChange={setRequiresRender}
        onRenderingChange={setIsRendering}
      />

      <header className='w-full h-15 px-7.5 flex-none bg-main-layer rounded-xl grid grid-cols-3'>
        <div className='flex items-center gap-2 text-sms text-icon-gray'>
          <button
            type='button'
            className='hover:text-white'
            onClick={() => navigate(-1)}
          >
            <ArrowLeftIcon className='w-4 h-4 fill-current' />
          </button>

          <NameField value={pack.name} onChange={handleChangeName} />
          <BetaBadge />
        </div>
        <div className='flex justify-center items-center gap-5'>
          <button
            type='button'
            className='text-icon-gray hover:text-white'
            onClick={handleCourseSettingsEdit}
          >
            <SettingIcon className='w-6 h-6 fill-current' />
          </button>
          {device === 'mobile' && (
            <button
              type='button'
              className='text-icon-gray hover:text-white'
              onClick={() => setDevice('desktop')}
            >
              <DesktopIcon className='w-6 h-6 fill-current' />
            </button>
          )}
          {device === 'desktop' && (
            <button
              type='button'
              className='text-icon-gray hover:text-white'
              onClick={() => setDevice('mobile')}
            >
              <MobileIcon className='w-6 h-6 fill-current' />
            </button>
          )}
          <button
            type='button'
            className='text-icon-gray hover:text-white'
            onClick={() => setIsPreviewing(true)}
          >
            <PlayIcon className='w-6 h-6 fill-current text-icon-gray hover:text-white' />
          </button>
        </div>
        <div className='flex justify-end items-center gap-6'>
          {(isRendering || isRenderRequesting) && (
            <div className='flex flex-col items-center gap-1'>
              <div className='text-3xs font-medium text-white'>
                Rendering ... check again in a few minutes.
              </div>
            </div>
          )}
          {isRendering || isRenderRequesting || requiresRender ? (
            <button
              type='button'
              className='btn-primary w-50 h-10 flex justify-center items-center gap-1'
              onClick={handleRenderAvatars}
              disabled={isRenderRequesting || isRendering}
            >
              <RenderIcon className='w-4 h-4 fill-current' />
              Render Avatars
            </button>
          ) : (
            <button
              type='button'
              className='btn-secondary w-30 h-10'
              onClick={() => {
                navigate(-1);
              }}
            >
              Done
            </button>
          )}
        </div>
      </header>

      <main className='w-full flex-1 overflow-hidden flex gap-4'>
        <TrainingEditorSidebar
          pack={pack}
          stores={stores}
          onStoresChange={setStores}
          selectedGameId={selectedGameId}
          onSelectedGameIdChange={setSelectedGameId}
        />

        <div className='flex-1 min-w-98 flex justify-center'>
          <div
            className={`
              relative bg-black text-white border border-secondary rounded-lg overflow-hidden ${
                device === 'mobile' ? 'w-98 h-174' : 'w-full h-full'
              }
            `}
          >
            <DevicePreview ref={devicePreview}>
              {selectedStore && (
                <TrainingEditorDetail
                  pack={pack}
                  stores={stores}
                  selectedGameId={selectedGameId}
                  store={selectedStore}
                  animator={animator}
                />
              )}
            </DevicePreview>
          </div>
        </div>

        <div className='flex-none w-72 h-full bg-main-layer rounded-xl p-2.5'>
          {selectedStore && (
            <TrainingEditorDetailSidebar
              store={selectedStore}
              animator={animator}
              devicePreview={devicePreview}
            />
          )}
        </div>
      </main>
    </div>
  );
}
