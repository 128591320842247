import Select from 'react-select';

import { EnumsSlideBackgroundOption } from '@lp-lib/api-service-client/public';

import { useInstance } from '../../../hooks/useInstance';
import { buildReactSelectStyles } from '../../../utils/react-select';
import { type Option } from '../../common/Utilities';

export function SlideBlockBackgroundSelect(props: {
  value: EnumsSlideBackgroundOption;
  onChange: (value: EnumsSlideBackgroundOption) => void;
}) {
  const styles = useInstance(() =>
    buildReactSelectStyles<Option<EnumsSlideBackgroundOption>>({
      override: { control: { height: 48 } },
    })
  );

  const SLIDE_HOST_DISPLAY_CONFIG = [
    {
      label: 'None',
      value: EnumsSlideBackgroundOption.SlideBackgroundOptionNone,
    },
    {
      label: 'Brand Color',
      value: EnumsSlideBackgroundOption.SlideBackgroundOptionBrand,
    },
    {
      label: 'Avatar with Overlay',
      value: EnumsSlideBackgroundOption.SlideBackgroundOptionHost,
    },
  ];

  return (
    <Select<Option<EnumsSlideBackgroundOption>>
      options={SLIDE_HOST_DISPLAY_CONFIG}
      value={SLIDE_HOST_DISPLAY_CONFIG.find(
        (option) => option.value === props.value
      )}
      classNamePrefix='select-box-v2'
      className='w-full'
      styles={styles}
      onChange={(option) => {
        if (!option) return;
        props.onChange(option.value);
      }}
      formatOptionLabel={(option) => (
        <div className='flex items-center gap-2'>{option.label}</div>
      )}
    />
  );
}
