import { useEffect, useMemo, useState } from 'react';
import { match } from 'ts-pattern';
import { useSnapshot } from 'valtio';

import {
  type DtoGamePack,
  EnumsGamePackChangeLevel,
} from '@lp-lib/api-service-client/public';
import { type Block, BlockType } from '@lp-lib/game';

import { GameEditorStore } from '../../../components/Game/GameEditorStore';
import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { apiService } from '../../../services/api-service';
import { type Game } from '../../../types/game';
import { type Action, ActionSheet } from '../../ActionSheet';
import { DragDropList } from '../../common/DragDrop';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import { BlockIcon } from '../../Game/Blocks';
import { BlockKnifeUtils } from '../../Game/Blocks/Shared';
import { ArrowDownIcon } from '../../icons/Arrows/Default';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { DuplicateIcon } from '../../icons/DuplicateIcon';
import { EditIcon } from '../../icons/EditIcon';
import { LockIcon } from '../../icons/LockIcon';
import { OptionsIcon } from '../../icons/OptionsIcon';
import { RenderIcon } from '../../icons/RenderIcon';
import { ToolsIcon } from '../../icons/ToolsIcon';
import { NewSlideButton } from './NewSlideButton';
import { RenameModal } from './Shared/RenameModal';
import { TrainingEditorUtils } from './utils';

function TrainingSlideActionButton(props: {
  store: GameEditorStore;
  block: Block;
  selected: boolean;
  onRename: () => void;
}) {
  const { store, block, selected, onRename } = props;

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'rename',
      icon: <EditIcon />,
      text: 'Rename',
      onClick: onRename,
    },
    {
      kind: 'button',
      key: 'duplicate',
      icon: <DuplicateIcon />,
      text: 'Duplicate',
      onClick: async () => {
        store.duplicateBlock(block.id);
      },
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: async () => {
        store.deleteBlock(block.id);
      },
    },
  ];

  return (
    <ActionSheet
      containerClassName={`w-6 h-6 flex justify-center items-center ${
        selected ? 'text-white' : 'text-transparent group-hover:text-icon-gray'
      }`}
      actions={actions}
      placement='right-start'
      offset={[0, 12]}
      optionsChildren={
        <div className='w-full h-full flex justify-center items-center'>
          <OptionsIcon className='w-4 h-4 fill-current' />
        </div>
      }
    />
  );
}

function TrainingSlideItem(props: {
  store: GameEditorStore;
  block: Block;
  index: number;
  selected: boolean;
  onSelect: () => void;
}) {
  const { store, block, index, selected, onSelect } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const requiresRender = useMemo(
    () => TrainingEditorUtils.IsBlockRenderRequired(block),
    [block]
  );
  const isRendering = useMemo(
    () => TrainingEditorUtils.IsBlockRendering(block),
    [block]
  );
  const blockTitle =
    block.fields.title || BlockKnifeUtils.Summary(block).title || 'Untitled';

  const handleTitleChange = useLiveCallback(async (value: string) => {
    store.blockEditorStore.updateEditingBlockFieldLocalFirst(
      block.id,
      'title',
      value
    );
  });

  const handleRename = useLiveCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <RenameModal
          defaultValue={blockTitle}
          title='Rename Slide'
          onCancel={p.internalOnCancel}
          onSave={(next) => {
            handleTitleChange(next);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });

  return (
    <div
      className={`w-full flex items-center cursor-pointer group ${
        selected ? 'text-white' : 'text-icon-gray'
      }`}
      onClick={onSelect}
    >
      <div
        className={`flex-none w-1 h-6 rounded-lg ${
          selected ? 'bg-lp-red-002' : 'bg-transparent'
        }`}
      />
      <div className='ml-1 flex-1 overflow-hidden flex items-center hover:bg-light-gray p-0.5 pr-1.5 rounded-lg'>
        <div className='flex-none w-4.5 h-full text-sms flex justify-center items-center'>
          {index + 1}
        </div>
        <div className='flex-none ml-1 w-6 h-6 relative'>
          <div
            className={`
              w-full h-full rounded-md flex justify-center items-center text-white
              ${match(block.type)
                .with(BlockType.SLIDE, () => 'bg-blue-005')
                .otherwise(() => 'bg-[#00D0C4]')}
                ${requiresRender || isRendering ? 'opacity-30' : 'opacity-100'}
            `}
          >
            <BlockIcon
              blockType={block.type}
              className={`w-2/3 h-2/3 fill-current block-v2-icon`}
            />
          </div>
          {(requiresRender || isRendering) && (
            <div className='absolute inset-0 bg-blue-005 bg-opacity-30 rounded-md flex justify-center items-center text-white'>
              {isRendering ? (
                <LockIcon className='w-4 h-4 fill-current' />
              ) : (
                <RenderIcon className='w-4 h-4 fill-current' />
              )}
            </div>
          )}
        </div>
        <div className='flex-1 ml-2 text-sms truncate'>{blockTitle}</div>
        <TrainingSlideActionButton
          store={store}
          block={block}
          selected={selected}
          onRename={handleRename}
        />
      </div>
    </div>
  );
}

function TrainingSlideGroupActionsButton(props: {
  selected: boolean;
  onDelete: () => void;
  onRename: () => void;
}) {
  const { selected, onDelete, onRename } = props;

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'rename',
      icon: <EditIcon />,
      text: 'Rename',
      onClick: onRename,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: onDelete,
    },
  ];

  return (
    <ActionSheet
      containerClassName={`w-6 h-6 flex justify-center items-center ${
        selected ? 'text-white' : 'text-transparent group-hover:text-icon-gray'
      }`}
      actions={actions}
      placement='right-start'
      offset={[0, 12]}
      optionsChildren={
        <div className='w-full h-full flex justify-center items-center'>
          <OptionsIcon className='w-4 h-4 fill-current' />
        </div>
      }
    />
  );
}

function TrainingSlideGroupHeader(props: {
  store: GameEditorStore;
  game: Game;
  blocks: Block[];
  selected: boolean;
  onSelect: () => void;
  expanded: boolean;
  onExpand: () => void;
  onDelete: (id: string) => void;
}) {
  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const {
    store,
    game,
    blocks,
    selected,
    onSelect,
    expanded,
    onExpand,
    onDelete,
  } = props;

  const handleChangeName = useLiveCallback(async (value: string) => {
    const resp = await apiService.game.update(game.id, {
      name: value,
    });
    if (!resp.data.game) return;
    store.updateGame(resp.data.game);
  });

  const handleRename = useLiveCallback(() => {
    triggerModal({
      kind: 'custom',
      element: (p) => (
        <RenameModal
          defaultValue={game.name}
          title='Rename Slide Group'
          onCancel={p.internalOnCancel}
          onSave={(next) => {
            handleChangeName(next);
            p.internalOnConfirm();
          }}
        />
      ),
    });
  });

  return (
    <div
      className={`w-full flex items-center group cursor-pointer ${
        selected ? 'text-white' : 'text-icon-gray'
      }`}
      onClick={onSelect}
    >
      <div
        className={`flex-none w-1 h-6 rounded-lg ${
          selected ? 'bg-lp-red-002' : 'bg-transparent'
        }`}
      />
      <div className='ml-0.5 flex-1 overflow-hidden h-7 flex items-center hover:bg-light-gray p-0.5 pr-1.5 rounded-lg'>
        <div className='flex-none ml-1 w-11.5 h-full rounded-md bg-tertiary p-1 flex justify-between items-center gap-2 text-white'>
          <ToolsIcon className='w-4 h-4 fill-current' />
          <div className='flex-1 text-sms'>{blocks.length}</div>
        </div>
        <div
          className={`flex-1 truncate ml-2 text-sms font-medium ${
            selected ? 'text-white' : 'text-icon-gray group-hover:text-white'
          }`}
        >
          {game.name}
        </div>
        <div
          className='ml-1 flex items-center gap-1'
          onClick={(e) => e.stopPropagation()}
        >
          <TrainingSlideGroupActionsButton
            selected={selected}
            onDelete={() => onDelete(game.id)}
            onRename={handleRename}
          />
          <button
            type='button'
            className='w-6 h-6 flex justify-center items-center'
            onClick={onExpand}
          >
            <ArrowDownIcon
              className={`w-4 h-4 fill-current ${
                expanded ? 'rotate-180' : ''
              } transition-transform`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

function TrainingSlideGroupContainer(props: {
  store: GameEditorStore;
  selected: boolean;
  onSelect: () => void;
  onDelete: (id: string) => void;
}) {
  const { store, selected, onSelect, onDelete } = props;
  const { game, selectedBlockId } = useSnapshot(store.state);
  const { blocks } = useSnapshot(store.blockEditorStore.state);

  const [expanded, setExpanded] = useState(true);
  useEffect(() => {
    // force open in case block length changes
    setExpanded(true);
  }, [blocks.length]);

  const [dndRoot, setDndRoot] = useState<HTMLElement | null>(null);

  if (!game) return null;

  return (
    <div
      ref={setDndRoot}
      className='w-full pl-0.5 pr-1.5 py-2 rounded-xl border border-secondary flex flex-col gap-3'
    >
      <TrainingSlideGroupHeader
        game={game as Game}
        store={store}
        blocks={blocks as Block[]}
        selected={selected && !selectedBlockId}
        onSelect={() => {
          onSelect();
          store.setSelectedBlockId(null);
        }}
        expanded={expanded}
        onExpand={() => setExpanded(!expanded)}
        onDelete={onDelete}
      />

      {expanded && blocks.length > 0 && (
        <div className='w-full flex flex-col gap-3 text-white cursor-pointer'>
          <DragDropList
            rootElement={dndRoot}
            requireRootElement
            items={blocks as Block[]}
            type={`slides-${game.id}`}
            onMove={(from, to) => {
              store.moveBlocks(from, to);
            }}
            render={({ item: block, index, style, ref, drag }) => (
              <div className='w-full' style={style} ref={ref}>
                <div ref={drag}>
                  <TrainingSlideItem
                    key={block.id}
                    store={store}
                    block={block}
                    index={index}
                    selected={selected && selectedBlockId === block.id}
                    onSelect={() => {
                      onSelect();
                      store.setSelectedBlockId(block.id);
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
      )}
    </div>
  );
}

export function TrainingEditorSidebar(props: {
  pack: DtoGamePack;
  stores: GameEditorStore[];
  onStoresChange: (stores: GameEditorStore[]) => void;
  selectedGameId: string | null;
  onSelectedGameIdChange: (id: string | null) => void;
}) {
  const {
    pack,
    stores,
    onStoresChange,
    selectedGameId,
    onSelectedGameIdChange,
  } = props;

  const handleNewSlideGroup = async () => {
    const resp = await apiService.game.create({
      name: `Untitled Slide Group`,
    });
    if (!resp.data.game) return;
    const store = new GameEditorStore();
    store.setEditingGame(resp.data.game, {
      blocks: [],
    });
    store.setSelectedBlockId(null);

    const newStores = [...stores];
    const position = stores.findIndex(
      (s) => s.state.game?.id === selectedGameId
    );
    const newPosition = position === -1 ? newStores.length : position + 1;

    newStores.splice(newPosition, 0, store);
    onStoresChange(newStores);
    onSelectedGameIdChange(resp.data.game.id);
    await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: newStores.map((s) => s.state.game?.id || ''),
    });
  };

  const handleMoveSlideGroup = async (from: number, to: number) => {
    const newStores = [...stores];
    const store = newStores[from];
    newStores.splice(from, 1);
    newStores.splice(to, 0, store);
    onStoresChange(newStores);
    await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: newStores.map((s) => s.state.game?.id || ''),
    });
  };

  const handleDeleteSlideGroup = async (id: string) => {
    const newStores = stores.filter((s) => s.state.game?.id !== id);
    onStoresChange(newStores);
    await apiService.gamePack.update(pack.id, {
      changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelNegligible,
      childrenIds: newStores.map((s) => s.state.game?.id || ''),
    });
  };

  const handleAddBlock = (blockType: BlockType) => {
    const selectedStore = stores.find(
      (s) => s.state.game?.id === selectedGameId
    );
    if (!selectedStore || !selectedGameId) return;

    const position = selectedStore.blockEditorStore.state.blocks.findIndex(
      (b) => b.id === selectedStore.state.selectedBlockId
    );
    const newPosition =
      position === -1
        ? selectedStore.blockEditorStore.state.blocks.length
        : position + 1;
    selectedStore.createBlock(selectedGameId, newPosition, blockType);
  };

  const [dndRoot, setDndRoot] = useState<HTMLElement | null>(null);

  return (
    <div className='flex-none w-72 h-full bg-main-layer rounded-xl p-2 flex flex-col gap-2'>
      {selectedGameId ? (
        <NewSlideButton
          onAddSlideGroup={handleNewSlideGroup}
          onAddBlock={handleAddBlock}
        />
      ) : (
        <button
          type='button'
          onClick={handleNewSlideGroup}
          className='w-full h-10 btn-secondary text-sms'
        >
          + New Slide Group
        </button>
      )}

      <div
        ref={setDndRoot}
        className='w-full flex-1 overflow-y-auto scrollbar flex flex-col gap-2'
      >
        <DragDropList
          rootElement={dndRoot}
          requireRootElement
          items={stores.map((s) => ({
            id: s.state.game?.id || '',
            store: s,
          }))}
          type='slide-groups'
          onMove={handleMoveSlideGroup}
          render={({ item: { store }, style, ref, drag }) => (
            <div className='w-full' style={style} ref={ref}>
              <div ref={drag}>
                <TrainingSlideGroupContainer
                  key={store.state.game?.id}
                  store={store}
                  selected={selectedGameId === store.state.game?.id}
                  onSelect={() =>
                    onSelectedGameIdChange(store.state.game?.id ?? null)
                  }
                  onDelete={handleDeleteSlideGroup}
                />
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}
