import cloneDeep from 'lodash/cloneDeep';
import { type RefObject, useEffect, useMemo, useState } from 'react';
import ContentEditable from 'react-contenteditable';

import {
  EnumsDialogueGenerationType,
  EnumsMediaScene,
  EnumsSlideBackgroundOption,
  EnumsSlideLayout,
  type ModelsDialogue,
  type ModelsMediaAsset,
} from '@lp-lib/api-service-client/public';
import { type SlideBlock, type SlideBlockAnimationKey } from '@lp-lib/game';

import { useLiveCallback } from '../../../hooks/useLiveCallback';
import { fromMediaDTO, toMediaDTO } from '../../../utils/api-dto';
import { ImagePickPriorityHighToLow, MediaUtils } from '../../../utils/media';
import { useAwaitFullScreenConfirmCancelModal } from '../../ConfirmCancelModalContext';
import {
  BlockAnimation,
  type BlockAnimator,
  BlockAnimatorProvider,
  useBlockAnimator,
} from '../../GameV2/apis/BlockAnimationControl';
import { useOrgMasqueradeFallback } from '../../GameV2/apis/OrgMasqueradeFallback';
import { CommonButton } from '../../GameV2/design/Button';
import { EditableText } from '../../GameV2/design/Editable';
import { ScrollableContent } from '../../GameV2/design/ScrollableContent';
import { TrainingLogo } from '../../GameV2/design/TrainingLogo';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { MediaUploader } from '../../MediaUploader/MediaUploader';
import { SwitcherControlled } from '../../Switcher';
import { useLogoBrandColor } from '../../VenueOrgLogoAverageColor/useLogoBrandColor';
import { DialogueEditor } from '../../VoiceOver/DialogueEditor';
import {
  usePersonalities,
  usePersonality,
} from '../../VoiceOver/usePersonalities';
import { VoiceOverUtils } from '../../VoiceOver/utils';
import { BlockMusicSelect } from './BlockMusicSelect';
import { useBlockFieldController, useTrainingSlideEditor } from './hooks';
import { BlockAnimationsEditor } from './Shared/BlockAnimationsEditor';
import { CaptionPlaceholder } from './Shared/CaptionPlaceholder';
import { useDevicePreviewFrame } from './Shared/DevicePreview';
import { SlideBlockBackgroundSelect } from './SlideBlockBackgroundSelect';
import { SlideBlockLayoutSelect } from './SlideBlockLayoutSelect';
import { type TrainingSlideEditorProps } from './types';

const Animate = BlockAnimation<SlideBlockAnimationKey>;

function EditableFieldText(
  props: TrainingSlideEditorProps<SlideBlock> & {
    field: 'heading' | 'subtitle' | 'buttonText';
  }
) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    props.field
  );

  return (
    <EditableText
      value={value ?? ''}
      onBlur={(value) => {
        updateLocal(value);
        updateRemote(value);
      }}
    />
  );
}

function htmlToBulletPoints(html: string) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const liElements = doc.querySelectorAll('li');

  return Array.from(liElements).map((li) => li.textContent?.trim() ?? '');
}

function bulletPointsToHtml(value: string[], blockId: string) {
  return value && value.length > 0
    ? value
        .map(
          (p, i) =>
            `<li data-animation-key="${blockId}-bullet-${i + 1}">${p}</li>`
        )
        .join('')
    : `<li data-animation-key="${blockId}-bullet-1"></li>`;
}

function EditableBulletPoints(props: TrainingSlideEditorProps<SlideBlock>) {
  const devicePreview = useDevicePreviewFrame();
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'bulletPoints'
  );
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  const [points, setPoints] = useState(value);
  const [html, setHtml] = useState(() =>
    bulletPointsToHtml(value, props.block.id)
  );
  const handleBlur = useLiveCallback(() => {
    const points = htmlToBulletPoints(html);
    const nextHtml = bulletPointsToHtml(points, props.block.id);
    setPoints(points);
    setHtml(nextHtml);

    updateLocal(points);
    updateRemote(points);
  });
  const registerBullets = useLiveCallback((points: string[]) => {
    // register the bullet points.
    for (let i = 0; i < points.length; i++) {
      const k = `bullet-${i + 1}`;
      const id = `${props.block.id}-${k}`;
      const el = (devicePreview?.contentDocument ?? document).querySelector(
        `li[data-animation-key="${id}"]`
      );
      if (!el) continue;
      animator.register(k as SlideBlockAnimationKey, el as HTMLElement, false);
    }
  });

  useEffect(() => {
    registerBullets(points);
  }, [registerBullets, points]);

  return (
    <ContentEditable
      tagName='ol'
      className={`
        outline-none list-decimal list-outside
        text-base sm:text-xl lg:text-2xl
        space-y-2 sm:space-y-4 pl-6
      `}
      html={html}
      onChange={(e) => {
        setHtml(e.currentTarget.innerHTML);
      }}
      onBlur={handleBlur}
    />
  );
}

function MediaField(props: TrainingSlideEditorProps<SlideBlock>) {
  const { value, updateLocal, updateRemote } = useBlockFieldController(
    props.block,
    'media'
  );

  return (
    <MediaUploader
      media={fromMediaDTO(value?.media)}
      scene={EnumsMediaScene.MediaSceneBlockMedia}
      video={false}
      width='w-full'
      onUploadSuccess={(media) => {
        const asset: ModelsMediaAsset = {
          media: toMediaDTO(media),
          data: {
            id: media.id,
          },
        };
        updateLocal(asset);
        updateRemote(asset);
      }}
      onMediaDelete={() => {
        updateLocal(null);
        updateRemote(null);
      }}
      objectFit='object-contain'
    />
  );
}

function BigTitle(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col gap-5'>
      <Logo />
      <Animate
        name='heading'
        noInit
        className='text-3.5xl sm:text-4.25xl lg:text-5xl font-bold leading-normal'
      >
        <EditableFieldText {...props} field='heading' />
      </Animate>
      <Animate name='subtitle' noInit>
        <EditableFieldText {...props} field='subtitle' />
      </Animate>
    </div>
  );
}

function TitleAndText(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col gap-5'>
      <Logo />
      <Animate
        name='heading'
        noInit
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableFieldText {...props} field='heading' />
      </Animate>
      <Animate name='subtitle' noInit>
        <EditableFieldText {...props} field='subtitle' />
      </Animate>
    </div>
  );
}

function BulletPoints(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col gap-5'>
      <Logo />
      <Animate
        name='heading'
        noInit
        className='text-xl sm:text-2xl lg:text-3.5xl font-bold leading-normal'
      >
        <EditableFieldText {...props} field='heading' />
      </Animate>
      <div className='min-h-0 flex-1 text-base sm:text-xl lg:text-2xl'>
        <EditableBulletPoints {...props} />
      </div>
    </div>
  );
}

function Classic(props: TrainingSlideEditorProps<SlideBlock>) {
  return (
    <div className='w-full flex flex-col sm:flex-row gap-5'>
      <div className='w-full sm:w-1/2 flex flex-col gap-5'>
        <Logo />
        <Animate
          name='heading'
          noInit
          className='text-xl font-bold leading-normal'
        >
          <EditableFieldText {...props} field='heading' />
        </Animate>
        <Animate name='subtitle' noInit className='flex-1'>
          <EditableFieldText {...props} field='subtitle' />
        </Animate>
      </div>

      <Animate name='media' noInit className='w-full sm:w-1/2'>
        <MediaField {...props} />
      </Animate>
    </div>
  );
}

function Content(props: TrainingSlideEditorProps<SlideBlock>) {
  switch (props.block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutClassic:
      return <Classic {...props} />;
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      return <TitleAndText {...props} />;
    case EnumsSlideLayout.SlideLayoutBigTitle:
      return <BigTitle {...props} />;
    case EnumsSlideLayout.SlideLayoutBulletPoints:
      return <BulletPoints {...props} />;
    case EnumsSlideLayout.SlideLayoutFullHost:
      return null;
    default:
      return null;
  }
}

function FullHostAvatar(
  props: TrainingSlideEditorProps<SlideBlock> & {
    className?: string;
  }
) {
  const personalityId =
    props.block.fields.dialogue?.entries?.at(0)?.personalityId;
  const personality = usePersonality(personalityId, {
    fallbackToDefault: true,
  });
  const mediaUrl = MediaUtils.PickMediaUrl(
    fromMediaDTO(personality?.profileImage?.media),
    {
      priority: ImagePickPriorityHighToLow,
    }
  );
  if (!mediaUrl) return null;
  return (
    <div className={`relative w-full h-full ${props.className}`}>
      <img src={mediaUrl} alt='' className='w-full h-full object-cover' />
      <div className='absolute inset-0 rounded-full flex justify-center items-center'>
        <p className={`text-icon-gray font-bold text-5xl`}>Placeholder</p>
      </div>
    </div>
  );
}

function Background(props: TrainingSlideEditorProps<SlideBlock>) {
  const { block } = props;

  const { data: color } = useLogoBrandColor(useOrgMasqueradeFallback());

  const brandColor = useMemo(() => {
    return color?.cssRGBA(1);
  }, [color]);

  if (block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost)
    return (
      <div className='fixed inset-0'>
        <FullHostAvatar {...props} className='w-full h-full object-cover' />
      </div>
    );
  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    return (
      <div className='fixed inset-0'>
        <FullHostAvatar {...props} className='w-full h-full object-cover' />
        <Animate
          name='overlay'
          noInit
          className={`absolute inset-0 bg-opacity-60 bg-black`}
        />
      </div>
    );
  }
  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionBrand
  ) {
    return (
      <div
        className='fixed inset-0 opacity-20'
        style={{
          backgroundColor: brandColor,
        }}
      />
    );
  }

  return null;
}

function Left(props: TrainingSlideEditorProps<SlideBlock>) {
  const isFullHostLayout =
    props.block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;
  const showContent = !isFullHostLayout;

  return (
    <>
      <Background {...props} />

      <div className='relative w-full h-full p-5 flex flex-col justify-between items-center text-white'>
        {isFullHostLayout && (
          <div className='absolute top-[70%] w-full'>
            <CaptionPlaceholder />
          </div>
        )}
        {showContent && (
          <div className='w-full min-h-0 flex-1 p-5 sm:p-8 lg:p-10'>
            <ScrollableContent>
              <Content {...props} />
            </ScrollableContent>
          </div>
        )}
        {!props.block.fields.hideButton && (
          <div
            className={`${
              isFullHostLayout ? 'mt-auto' : ''
            } w-full flex items-center justify-center gap-2`}
          >
            <CommonButton
              variant='gray'
              styles={{
                size: 'h-full',
                disabled: 'disabled:opacity-100 disabled:cursor-auto',
              }}
              style={{
                aspectRatio: '1/1',
              }}
              disabled
            >
              <RefreshIcon className='w-4 h-4 fill-current' />
            </CommonButton>
            <CommonButton
              variant='brand'
              className='flex-none'
              styles={{
                disabled: 'disabled:opacity-100 disabled:cursor-auto',
              }}
              disabled
            >
              <EditableFieldText {...props} field='buttonText' />
            </CommonButton>
          </div>
        )}
      </div>
    </>
  );
}

function Logo() {
  const animator = useBlockAnimator<SlideBlockAnimationKey>();
  return <TrainingLogo ref={animator.ref('logo', false)} />;
}

function HideButtonField(props: TrainingSlideEditorProps<SlideBlock>) {
  const {
    value: hideButton,
    updateLocal,
    updateRemote,
  } = useBlockFieldController(props.block, 'hideButton');

  return (
    <div>
      <p className='text-base text-white font-bold mb-1'>Button</p>
      <div className='flex items-center justify-between'>
        <div>
          <p className='text-sms'>Show button?</p>
          {/* <p className='text-icon-gray text-xs'>
            {hideButton
              ? 'Auto-advance to next slide'
              : 'Advance with button click'}
          </p> */}
        </div>
        <SwitcherControlled
          name={`${props.block.id}-hideButton`}
          checked={!hideButton}
          onChange={(checked: boolean): void => {
            updateLocal(!checked);
            updateRemote(!checked);
          }}
        />
      </div>
    </div>
  );
}

function getAnimatableKeysForBlock(
  block: SlideBlock
): SlideBlockAnimationKey[] {
  const result: SlideBlockAnimationKey[] = [];
  if (
    block.fields.backgroundOption ===
    EnumsSlideBackgroundOption.SlideBackgroundOptionHost
  ) {
    result.push('overlay');
  }
  switch (block.fields.layout) {
    case EnumsSlideLayout.SlideLayoutBigTitle:
    case EnumsSlideLayout.SlideLayoutTitleAndText:
      result.push('logo', 'heading', 'subtitle');
      return result;
    case EnumsSlideLayout.SlideLayoutClassic:
      result.push('logo', 'heading', 'subtitle', 'media');
      return result;
    case EnumsSlideLayout.SlideLayoutBulletPoints: {
      result.push('logo', 'heading');
      for (let i = 0; i < block.fields.bulletPoints.length; i++) {
        result.push(`bullet-${i + 1}`);
      }
      return result;
    }
    default:
      return [];
  }
}

function findTriggers(dialogue: Nullable<ModelsDialogue>) {
  if (!dialogue) return [];

  const triggerSet = new Set<string>();
  for (let i = 0; i < dialogue.entries.length; i++) {
    const entry = dialogue.entries[i];
    const matches = entry.script.matchAll(/<mark name="([^"]+)"\s*\/>/g);
    for (const match of matches) {
      triggerSet.add(match[1]);
    }
  }
  return Array.from(triggerSet);
}

function Right(
  props: TrainingSlideEditorProps<SlideBlock> & {
    devicePreview: RefObject<HTMLIFrameElement>;
  }
) {
  const { block, devicePreview } = props;
  const { onChange, onBlur } = useTrainingSlideEditor(props);

  const triggerModal = useAwaitFullScreenConfirmCancelModal();
  const { data: personalities } = usePersonalities();

  const isRendering = VoiceOverUtils.IsDialogueRendering(block.fields.dialogue);

  const confirmFullHostChange = async (fullHost: boolean) => {
    if (isRendering) {
      await triggerModal({
        kind: 'confirm-cancel',
        prompt: (
          <div className='p-5 text-white'>
            <div className='text-2xl font-medium text-center'>Attention</div>
            <div className='mt-4 text-sms'>
              You are not able to change the layout while the script avatars are
              rendering.
            </div>
          </div>
        ),
        confirmBtnLabel: 'Confirm',
        confirmBtnVariant: 'primary',
        confirmOnly: true,
      });
      return false;
    }

    // from full host to anything else
    if (!fullHost) {
      // if there are rendered avatars, we need to confirm.
      if (VoiceOverUtils.ContainsRenderedAvatars(block.fields.dialogue)) {
        const { result } = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white'>
              <div className='text-2xl font-medium text-center'>Warning</div>
              <div className='mt-4 text-sms'>
                This layout change will delete existing rendered avatars from
                this slide. This action cannot be undone.
              </div>
            </div>
          ),
          confirmBtnLabel: 'Confirm',
          confirmBtnVariant: 'delete',
          cancelBtnLabel: 'Cancel',
        });
        if (result === 'canceled') return false;
      }

      // convert all offline generated avatars to client generated.
      if (block.fields.dialogue) {
        const dialogue = VoiceOverUtils.ConvertDialogueToClient(
          block.fields.dialogue
        );
        onChange('dialogue', dialogue);
        onBlur('dialogue', dialogue);
      }
      return true;
    }

    // from anything else to full host
    if (fullHost) {
      //  if there are client generated avatars, we need to confirm.
      const containsClientGenerated = block.fields.dialogue?.entries.some(
        (e) =>
          e.generationType ===
          EnumsDialogueGenerationType.DialogueGenerationTypeClient
      );
      if (containsClientGenerated) {
        const { result } = await triggerModal({
          kind: 'confirm-cancel',
          prompt: (
            <div className='p-5 text-white'>
              <div className='text-2xl font-medium text-center'>Attention</div>
              <div className='mt-4 text-sms'>
                Changing to Full Host layout will require avatar generation.
                You'll need to render the avatars separately after the layout
                change.
              </div>
            </div>
          ),
          confirmBtnLabel: 'Continue',
          confirmBtnVariant: 'primary',
          cancelBtnLabel: 'Cancel',
        });
        if (result === 'canceled') return false;
      }

      if (block.fields.dialogue) {
        const dialogue = VoiceOverUtils.ConvertDialogueToOffline(
          block.fields.dialogue,
          personalities ?? []
        );
        onChange('dialogue', dialogue);
        onBlur('dialogue', dialogue);
      }

      return true;
    }
  };

  const fullHost =
    block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost ||
    block.fields.backgroundOption ===
      EnumsSlideBackgroundOption.SlideBackgroundOptionHost;
  const isBackgroundSelectHidden =
    block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost;

  return (
    <div className='w-full h-full flex flex-col gap-5 text-white overflow-y-auto scrollbar'>
      <div className='flex-1 max-h-[50%] overflow-visible'>
        <DialogueEditor
          offlineRendering={fullHost}
          value={block.fields.dialogue}
          onChange={(value) => {
            onChange('dialogue', value);
            onBlur('dialogue', value);

            // if we removed any triggers, we should remove them from the animations too.
            const triggers = findTriggers(value);
            const animations = block.fields.animations ?? {};
            const next = cloneDeep(animations);
            for (const [k, anim] of Object.entries(animations)) {
              if (
                anim?.enter?.triggerId &&
                !triggers.includes(anim.enter.triggerId)
              ) {
                delete next[k as SlideBlockAnimationKey];
              }
            }
            onChange('animations', next);
            onBlur('animations', next);
          }}
        />
      </div>

      <label>
        <p className='text-base text-white font-bold mb-1'>Slide Layout</p>
        <SlideBlockLayoutSelect
          value={block.fields.layout}
          onChange={async (value) => {
            const fullHostAfter =
              value === EnumsSlideLayout.SlideLayoutFullHost ||
              block.fields.backgroundOption ===
                EnumsSlideBackgroundOption.SlideBackgroundOptionHost;
            if (fullHost !== fullHostAfter) {
              const success = await confirmFullHostChange(fullHostAfter);
              if (!success) return;
            }
            onChange('layout', value);
            onBlur('layout', value);
          }}
        />
      </label>

      {!isBackgroundSelectHidden && (
        <label>
          <p className='text-base text-white font-bold mb-1'>Background</p>
          <SlideBlockBackgroundSelect
            value={
              block.fields.backgroundOption ??
              EnumsSlideBackgroundOption.SlideBackgroundOptionNone
            }
            onChange={async (value) => {
              const fullHostAfter =
                block.fields.layout === EnumsSlideLayout.SlideLayoutFullHost ||
                value === EnumsSlideBackgroundOption.SlideBackgroundOptionHost;
              if (fullHost !== fullHostAfter) {
                const success = await confirmFullHostChange(fullHostAfter);
                if (!success) return;
              }

              onChange('backgroundOption', value);
              onBlur('backgroundOption', value);
            }}
          />
        </label>
      )}

      <label>
        <p className='text-base text-white font-bold mb-1'>Background Music</p>
        <BlockMusicSelect
          value={block.fields.bgMusic}
          onChange={(value) => {
            onChange('bgMusic', value);
            // Do not persist the decorated media object.
            const out = cloneDeep(value);
            delete out?.asset.media;
            onBlur('bgMusic', out);
          }}
        />
      </label>

      <HideButtonField {...props} />
      <div className='flex-1 min-h-0 max-h-[25%]'>
        <BlockAnimationsEditor
          {...props}
          getAnimatableKeysForBlock={getAnimatableKeysForBlock}
          triggers={findTriggers(block.fields.dialogue)}
          preview
          highlightContainer={() =>
            devicePreview.current?.contentDocument?.body
          }
        />
      </div>
    </div>
  );
}

export function SlideBlockEditor(
  props: TrainingSlideEditorProps<SlideBlock> & {
    animator: BlockAnimator<SlideBlockAnimationKey>;
  }
) {
  useEffect(() => {
    props.animator.setAnimations(props.block.fields.animations ?? {}, false);
  }, [props.animator, props.block.fields.animations]);

  return (
    <BlockAnimatorProvider value={props.animator}>
      <Left {...props} />
    </BlockAnimatorProvider>
  );
}

export function SlideBlockSidebarEditor(
  props: TrainingSlideEditorProps<SlideBlock> & {
    animator: BlockAnimator<SlideBlockAnimationKey>;
    devicePreview: RefObject<HTMLIFrameElement>;
  }
) {
  return <Right {...props} />;
}
