import { useCallback, useRef } from 'react';

export function RenameModal(props: {
  defaultValue: string;
  placeholder?: string;
  title: React.ReactNode;
  onCancel: () => void;
  onSave: (name: string) => void;
}) {
  const { onCancel, onSave } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const handleSave = useCallback(() => {
    onSave(inputRef.current?.value ?? '');
  }, [onSave]);
  return (
    <div className='border border-secondary bg-black rounded-xl px-5 py-3 w-142 min-h-45'>
      <div className='w-full h-full flex flex-col text-white'>
        <div className='flex-none w-full py-2'>
          <div className='font-bold text-lg'>{props.title}</div>
        </div>

        <div className='w-full flex-1 min-h-0 pt-2 pb-4'>
          <input
            ref={inputRef}
            className={`w-full h-12.5 field m-0`}
            placeholder={props.placeholder}
            defaultValue={props.defaultValue}
            autoFocus
          />
        </div>

        <div className='mt-auto w-full flex items-center justify-end gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 py-2'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='button'
            className='btn-primary w-40 py-2'
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
    </div>
  );
}
