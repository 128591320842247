import { useMemo } from 'react';
import { useSnapshot } from 'valtio';

import { type DtoGamePack } from '@lp-lib/api-service-client/public';

import { type Game } from '../../../types/game';
import { fromDTOGamePack } from '../../../utils/api-dto';
import { type GameEditorStore } from '../../Game/GameEditorStore';
import { Overworld } from '../../GameV2/Overworld';
import { TrainingEditorUtils } from './utils';

export function SlideGroupEditor(props: {
  pack: DtoGamePack;
  stores: GameEditorStore[];
  selectedGame: Game;
}) {
  const { pack, stores, selectedGame } = props;

  const games = stores
    .map((store) => store.state.game)
    .filter(Boolean) as Game[];

  const names = useMemo(() => {
    return stores.map((store) => <ConnectedGameName store={store} />);
  }, [stores]);

  const selectedGameIndex = games.findIndex(
    (game) => game.id === selectedGame.id
  );

  const progression = TrainingEditorUtils.PreviewOverworldProgression(
    games,
    selectedGameIndex
  );

  return (
    <Overworld
      pack={fromDTOGamePack(pack)}
      games={games}
      gameNames={names}
      progression={progression}
      isPreview
    />
  );
}

function ConnectedGameName(props: { store: GameEditorStore }) {
  const name = useSnapshot(props.store.state).game?.name;
  return <>{name}</>;
}
