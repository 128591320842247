export function NoRenderIcon(props: { className?: string }) {
  return (
    <svg
      viewBox='0 0 16 15'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <path d='M13.1658 1.96684C12.3079 1.07015 11.2582 0.401306 10.1337 0.0323963C10.0653 0.0114951 9.99509 0 9.92676 0C9.77396 0 9.62305 0.0532991 9.49684 0.157805C9.30987 0.312478 9.20264 0.548653 9.20264 0.805758C9.20264 1.15586 9.41428 1.47148 9.71514 1.57076C10.6234 1.86757 11.469 2.40578 12.1591 3.12792C12.3925 3.37142 12.7399 3.41635 13.0189 3.23765C13.2201 3.10911 13.354 2.88755 13.3805 2.63256C13.4071 2.38069 13.3293 2.14034 13.1641 1.96893L13.1658 1.96684Z' />
      <path d='M13.7537 9.11066C13.9646 9.21829 14.2094 9.23207 14.4271 9.14855C14.6429 9.06675 14.7958 8.90573 14.848 8.70512C14.9893 8.18505 15.0589 7.64948 15.0589 7.11134C15.0589 6.57319 14.9873 6.03591 14.848 5.51756C14.7938 5.31693 14.64 5.15593 14.4271 5.07413C14.2065 4.99061 13.9636 5.00352 13.7585 5.10943C13.4711 5.25753 13.3299 5.5477 13.4063 5.83269C13.5176 6.24942 13.5747 6.67908 13.5747 7.11133C13.5747 7.54356 13.5176 7.97324 13.4063 8.38997C13.3299 8.67412 13.4711 8.96515 13.7527 9.11065L13.7537 9.11066Z' />
      <path d='M9.49518 14.1028C9.68025 14.2174 9.91278 14.2511 10.132 14.197C11.2566 13.92 12.3063 13.418 13.1641 12.7448C13.3293 12.6162 13.4071 12.4334 13.3805 12.2467C13.354 12.0537 13.2201 11.8889 13.0189 11.7924C12.9069 11.7383 12.7807 11.7124 12.6573 11.7124C12.4751 11.7124 12.2957 11.7689 12.1591 11.8748C11.4662 12.4153 10.6216 12.8209 9.71514 13.0437C9.41428 13.1183 9.20264 13.3528 9.20264 13.618C9.20264 13.811 9.30988 13.9875 9.49684 14.1044L9.49518 14.1028Z' />
      <path d='M5.70452 14.1923C5.93878 14.2565 6.1861 14.2165 6.38115 14.0806C6.57921 13.9446 6.69281 13.7351 6.69281 13.5061C6.69281 13.1942 6.46861 12.913 6.14688 12.8245C3.43642 12.0788 1.54519 9.72881 1.54519 7.11336C1.54519 4.49887 3.43734 2.14895 6.14688 1.4022C6.4686 1.31375 6.69281 1.03256 6.69281 0.72065C6.69281 0.491607 6.5792 0.282129 6.38115 0.143384C6.24441 0.0502783 6.08557 0 5.9227 0C5.8483 0 5.77592 0.00931055 5.70353 0.0307261C2.34756 0.958061 0 3.86868 0 7.10956C0 10.3516 2.34457 13.2638 5.70353 14.1884L5.70452 14.1923Z' />
      <path d='M9.56783 6.92002C9.51971 6.88814 9.46377 6.89596 9.42377 6.92814C9.02377 7.22408 8.53561 7.39221 7.99969 7.39221C7.46377 7.39221 6.97561 7.21627 6.57561 6.92814C6.53561 6.89627 6.47155 6.89627 6.43155 6.92002C5.48747 7.52815 5.01562 8.72002 5.01562 10.0882C5.01562 11.0323 10.9838 11.0323 10.9838 10.0882C10.9838 8.72002 10.5116 7.52818 9.56783 6.92002Z' />
      <path d='M9.78349 4.98409C9.78349 5.96937 8.98474 6.76817 7.99941 6.76817C7.01408 6.76817 6.21533 5.96942 6.21533 4.98409C6.21533 3.99881 7.01408 3.20001 7.99941 3.20001C8.98474 3.20001 9.78349 3.99876 9.78349 4.98409Z' />
    </svg>
  );
}

export function RenderIcon(props: { className?: string }) {
  return (
    <svg
      viewBox='0 0 16 15'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
    >
      <path d='M13.1658 1.96684C12.3079 1.07015 11.2582 0.401306 10.1337 0.0323963C10.0653 0.0114951 9.99509 0 9.92676 0C9.77396 0 9.62305 0.0532991 9.49684 0.157805C9.30987 0.312478 9.20264 0.548653 9.20264 0.805758C9.20264 1.15586 9.41428 1.47148 9.71514 1.57076C10.6234 1.86757 11.469 2.40578 12.1591 3.12792C12.3925 3.37142 12.7399 3.41635 13.0189 3.23765C13.2201 3.10911 13.354 2.88755 13.3805 2.63256C13.4071 2.38069 13.3293 2.14034 13.1641 1.96893L13.1658 1.96684Z' />
      <path d='M13.7534 9.11066C13.9644 9.21829 14.2091 9.23207 14.4269 9.14855C14.6426 9.06675 14.7955 8.90573 14.8478 8.70512C14.989 8.18505 15.0587 7.64948 15.0587 7.11134C15.0587 6.57319 14.9871 6.03591 14.8478 5.51756C14.7936 5.31693 14.6397 5.15593 14.4269 5.07413C14.2063 4.99061 13.9634 5.00352 13.7583 5.10943C13.4709 5.25753 13.3296 5.5477 13.4061 5.83269C13.5173 6.24942 13.5744 6.67908 13.5744 7.11133C13.5744 7.54356 13.5173 7.97324 13.4061 8.38997C13.3296 8.67412 13.4709 8.96515 13.7525 9.11065L13.7534 9.11066Z' />
      <path d='M9.49518 14.1028C9.68025 14.2174 9.91278 14.2511 10.132 14.197C11.2566 13.92 12.3063 13.418 13.1641 12.7448C13.3293 12.6162 13.4071 12.4334 13.3805 12.2467C13.354 12.0537 13.2201 11.8889 13.0189 11.7924C12.9069 11.7383 12.7807 11.7124 12.6573 11.7124C12.4751 11.7124 12.2957 11.7689 12.1591 11.8748C11.4662 12.4153 10.6216 12.8209 9.71514 13.0437C9.41428 13.1183 9.20264 13.3528 9.20264 13.618C9.20264 13.811 9.30988 13.9875 9.49684 14.1044L9.49518 14.1028Z' />
      <path d='M5.70452 14.1923C5.93878 14.2565 6.1861 14.2165 6.38115 14.0806C6.57921 13.9446 6.69281 13.7351 6.69281 13.5061C6.69281 13.1942 6.46861 12.913 6.14688 12.8245C3.43642 12.0788 1.54519 9.72881 1.54519 7.11336C1.54519 4.49887 3.43734 2.14895 6.14688 1.4022C6.4686 1.31375 6.69281 1.03256 6.69281 0.72065C6.69281 0.491607 6.5792 0.282129 6.38115 0.143384C6.24441 0.0502783 6.08557 0 5.9227 0C5.8483 0 5.77592 0.00931055 5.70353 0.0307261C2.34756 0.958061 0 3.86868 0 7.10956C0 10.3516 2.34457 13.2638 5.70353 14.1884L5.70452 14.1923Z' />
      <path d='M9.56808 6.91999C9.51995 6.88811 9.46401 6.89592 9.42401 6.92811C9.02401 7.22405 8.53585 7.39218 7.99993 7.39218C7.46401 7.39218 6.97585 7.21624 6.57585 6.92811C6.53585 6.89624 6.47179 6.89624 6.43179 6.91999C5.48771 7.52812 5.01587 8.71999 5.01587 10.0881C5.01587 11.0322 10.984 11.0322 10.984 10.0881C10.984 8.71999 10.5118 7.52815 9.56808 6.91999Z' />
      <path d='M9.78398 4.98409C9.78398 5.96937 8.98523 6.76817 7.9999 6.76817C7.01457 6.76817 6.21582 5.96942 6.21582 4.98409C6.21582 3.99881 7.01457 3.20001 7.9999 3.20001C8.98523 3.20001 9.78398 3.99876 9.78398 4.98409Z' />
    </svg>
  );
}
