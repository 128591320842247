import { useMemo } from 'react';

import { fromMediaDTO } from '../../../../utils/api-dto';
import {
  ImagePickPriorityHighToLow,
  MediaUtils,
} from '../../../../utils/media';
import { usePersonality } from '../../../VoiceOver/usePersonalities';

export function CaptionPlaceholder(props: { avatar?: React.ReactNode }) {
  return (
    <div className='w-full flex-none px-5 min-w-70 max-w-125 h-15 lg:h-20 flex items-center justify-center gap-2.5'>
      {props.avatar}
      <div
        className='font-bold text-white text-base lg:text-xl'
        style={{
          fontFamily: 'PT Sans Caption',
          textShadow: '1.5px 1.5px 1.5px black',
        }}
      >
        Caption will display here
      </div>
    </div>
  );
}

export function CaptionPlaceholderForPersonality(props: {
  personalityId: Nullable<string>;
}) {
  const personality = usePersonality(props.personalityId);
  const mediaUrl = useMemo(
    () =>
      MediaUtils.PickMediaUrl(fromMediaDTO(personality?.profileImage?.media), {
        priority: ImagePickPriorityHighToLow,
      }),
    [personality?.profileImage?.media]
  );

  return (
    <CaptionPlaceholder
      avatar={
        mediaUrl && (
          <div className='w-10 h-10 rounded-full overflow-hidden'>
            <img
              src={mediaUrl}
              alt={personality?.name}
              className='w-full h-full object-cover'
            />
          </div>
        )
      }
    />
  );
}
